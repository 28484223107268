<template>
<div>




  <v-card>
    <v-card-title style="font-weight: 700">План-конспекты</v-card-title>
    <v-container>


<!-- ПЕРВАЯ ЛИНИЯ  -->

      <v-row dense>


        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Проведение АСР при ДТП</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Классно-груповое
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                1 час
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_1.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>





        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Основные положения аварийно-спасательной подготовки</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Классно-груповое
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                1 час
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_2.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>




        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Сохранение жизни при неисправности СИЗОД</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Семинар
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                1 час
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_4.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>





<!-- ВТОРАЯ ЛИНИЯ  -->

<v-row dense>


<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Тактика проведения первоочередных АСР и тушение пожаров на объектах МЧС</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Практическое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_5.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Методы оценки своего функционального состояния и способы его регулирования</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_6.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Социальные гарантии, предоставляемые сотрудникам и членам их семей</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Лекция
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_7.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>





<!-- ТРЕТЬЯ ЛИНИЯ  -->

<v-row dense>

<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Тушение пожаров и проведение АСР в зданиях с массовым пребыванием людей</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Практическое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        2 часа
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_8.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Специальная защитная одежда и снаряжение пожарных</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_9.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Приборы подачи огнетушащих веществ</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-групповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_10.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>


























<!-- ЧЕТВЕРТАЯ ЛИНИЯ  -->

<v-row dense>

<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Аварийно-спасательное оборудование и пожарный инструмент</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_11.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Первая помощь при ожогах и обморожениях</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_12.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Основы организации и методики проведения занятий по ФП ЛС ПСП </v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_16.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>







<!-- ПЯТАЯ ЛИНИЯ  -->

<v-row dense>

<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Организация и содержание подготовки газодымозащитников в дежурном карауле</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_17.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Тушение пожаров в детских учреждениях и школах</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Практическое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_18.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Тактика тушения пожаров и проведения АСР в поврежденных ЗиС</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-групповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_19.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>









<!-- ШЕСТАЯ ЛИНИЯ  -->

<v-row dense>

<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Тренировочные занятия по решению ПТЗ (дневные)</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Практическое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_20.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Изучение объектов хранения нефти и её продуктов, порядок применения ПТВ</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_21.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Обеспечение устойчивости зданий и сооружений при пожаре</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-групповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        2 часа
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_22.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>





<!-- СЕДЬМАЯ ЛИНИЯ  -->

<v-row dense>

<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Организация гарнизонной и караульной службы</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_23.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>





<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Пожарные насосы</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-груповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_24.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>




<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Решение ПТЗ. Цель, задачи и организация занятий</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Классно-групповое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/pk/pk_25.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>



    </v-container>
  </v-card>



  <v-card>
    <v-card-title style="font-weight: 700">Методические планы</v-card-title>
    <v-container>


<!-- ПЕРВАЯ ЛИНИЯ  -->

      <v-row dense>


        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Тренировка в СИЗОД в ТДК</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Практическое
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                2 часа
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/mp/mp_1.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Вязка двойной спасательной петли</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Практическое
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                2 часа
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/mp/mp_2.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card
            color="#005495b0"
            dark
            height="200px"
          >
            <div class="d-flex flex-no-wrap justify-space-between" height="200px">
              <div>
                <v-card-title
                  class="text-h5" style="word-break: break-word"
                >Рабочая проверка ДАСВ. Организация работы поста безопасности</v-card-title>
                  <v-chip
                  class="ma-1 pa-1 ml-4"
                  color="green"
                  text-color="white"
                >
                Практическое
                </v-chip> 
                  <v-chip
                  class="ma-1 pa-1"
                  color="green"
                  text-color="white"
                >
                3 часа
                </v-chip>               
                    <v-btn
                    class="ma-1 pa-1"
                    outlined
                    rounded
                    small
                    target="_blank"
                    href="https://api.learn.mchs.ru/uploads/mpk/mp/mp_3.pdf"
                    download 
                    >
                    Скачать
                    </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>




<!-- ВТОРАЯ ЛИНИЯ  -->

<v-row dense>


<v-col cols="4">
  <v-card
    color="#005495b0"
    dark
    height="200px"
  >
    <div class="d-flex flex-no-wrap justify-space-between" height="200px">
      <div>
        <v-card-title
          class="text-h5" style="word-break: break-word"
        >Виды, назначения и ТХ АСГИ</v-card-title>
          <v-chip
          class="ma-1 pa-1 ml-4"
          color="green"
          text-color="white"
        >
        Практическое
        </v-chip> 
          <v-chip
          class="ma-1 pa-1"
          color="green"
          text-color="white"
        >
        1 час
        </v-chip>               
            <v-btn
            class="ma-1 pa-1"
            outlined
            rounded
            small
            target="_blank"
            href="https://api.learn.mchs.ru/uploads/mpk/mp/mp_4.pdf"
            download 
            >
            Скачать
            </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
</v-row>





















</v-container>
</v-card>

  



  
    </div>
</template>
<script>

    export default {
        name: "index",
        data: () => ({
            groups:[],
            items: [
              {
                color: '#1F7087',
                src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',

              },

            ],


            loading:true,
            viewModal:false,
            fileView:{}
            }),
        methods: {
            view(file){
                this.fileView=file
                this.viewModal=true
            },
            fetchData() {
                this.$http.get('documents/get-all').then(response => {
                    this.groups = response.data
                    this.loading=false
                })

            }
        },
        created() { this.fetchData() }
    };
</script>